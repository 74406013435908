import { z } from 'zod'

export const IdpSignInResponseBody = z.union([
	z.object({
		ok: z.literal(false),
		error: z.string(),
		code: z.enum(['auth_error', 'access_denied']).optional(),
	}),
	z.object({
		ok: z.literal(true),
		result: z.object({
			email: z.string(),
		}),
	}),
])

export const IdpInitResponseBody = z.object({
	ok: z.literal(true),
	result: z.object({
		url: z.string(),
	}),
})

export const BACK_LINK_SEARCH_PARAM = 'backLink'
