import { cls } from '@/lib/cls'
import { ReactNode, useState } from 'react'

type SoftErrorProps = {
	headline?: string
	error?: string
	action?: ReactNode
	isDismissEnabled?: boolean
}

export const SoftError = ({ headline, error, action, isDismissEnabled = true }: SoftErrorProps) => {
	const [isDismissed, setIsDismissed] = useState(false)
	if (isDismissed) {
		return null
	}
	return (
		<>
			<div className="container px-2 sm:px-5 fixed z-50 bottom-0 left-1/2 -translate-x-1/2">
				<div className="bg-ember-100 text-black text-center rounded-t-xl pt-8 sm:pt-16 pb-5 px-6 shadow-md">
					<p className="mb-12 text-xl md:text-[30px] max-w-[640px] mx-auto leading-[1.2] font-serif">
						{headline ? headline : 'Error occured while communicating with API. You can try to send prompt again.'}
					</p>
					{error && <p className="mb-12 text-sm max-w-[640px] mx-auto leading-[1.2] font-serif mt-5">{error}</p>}
					<div className="flex justify-center gap-x-3">
						{action && action}
						{isDismissEnabled && (
							<button
								className="px-11 py-4 bg-midnight-500 text-white rounded-md font-semibold hover:text-white disabled:bg-purple-20 min-w-[225px]"
								onClick={() => setIsDismissed(true)}
							>
								Dismiss
							</button>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

type SchemaFatalErrorProps = {
	message: string
}

export const SchemaFatalError = ({ message }: SchemaFatalErrorProps) => {
	return (
		<>
			<div className="h-screen bg-white">
				<div className="flex flex-col h-full justify-between pt-12 md:pt-20 pb-10 px-0 sm:px-20">
					<div className="">
						<h2 className="text-3xl sm:text-5xl lg:text-7xl xl:text-[100px] !leading-[1.15] font-medium max-w-[1150px] mb-9">
							Hold On, We've Hit a Speedbump!
						</h2>
						<p className="text-xl sm:text-2xl leading-tight font-medium max-w-[720px]">{message}</p>
					</div>
				</div>
			</div>
		</>
	)
}

type ApiErrorProps = {
	message?: React.ReactNode
	error?: string
	canBeDismissed?: boolean
	retry?: () => void
	severity?: 'orange' | 'red'
}

export const ApiError = ({ message = '', error, canBeDismissed = true, retry, severity = 'orange' }: ApiErrorProps) => {
	const [isErrorVisible, setIsErrorVisible] = useState(false)
	const [isDismissed, setIsDismissed] = useState(false)
	if (isDismissed) {
		return null
	}
	return (
		<>
			<div className="fixed z-40 left-0 top-0 right-0 bottom-0 bg-transparent pointer-events-none">
				<div className="container max-w-[730px] fixed z-50 bottom-10 text-orange-900 left-1/2 -translate-x-1/2">
					<div
						className={cls(
							'border-[3px] border-orange-900  text-left rounded-[10px] py-3 px-4 pointer-events-auto',
							canBeDismissed ? 'pr-6' : 'pr-4',
							severity === 'orange' ? 'bg-orange-100 text-orange-900' : '',
							severity === 'red' ? 'bg-red-600 text-white' : '',
						)}
					>
						{canBeDismissed && (
							<button type={'button'} className="absolute top-[10px] right-[10px]" onClick={() => setIsDismissed(true)}>
								<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
									<title>Close</title>
									<line x1="10.607" y1="3.53548" x2="3.53594" y2="10.6065" stroke="black" strokeWidth="2" />
									<line x1="10.6069" y1="10.6066" x2="3.53579" y2="3.53558" stroke="black" strokeWidth="2" />
								</svg>
							</button>
						)}
						<p className="leading-[1.43] font-inter">
							{message ? message : 'AI is not in the mood to talk right now. Please give us a few minutes to try and get AI to talk to you.'}
							{retry && (
								<>
									{' '}
									<button type={'button'} className="underline hover:no-underline text-orange-800" onClick={() => retry()}>
										Send message again
									</button>
								</>
							)}
						</p>
						{error && (
							<>
								{!isErrorVisible && (
									<div>
										<button type={'button'} className="underline hover:no-underline py-2 mt-2" onClick={() => setIsErrorVisible(true)}>
											Show me your error.
										</button>
									</div>
								)}
								{isErrorVisible && (
									<p
										className={cls(
											'py-2 px-3 leading-[1.43] font-inter mt-3 overflow-hidden text-ellipsis',
											severity === 'orange' ? 'bg-orange-50 ' : '',
											severity === 'red' ? 'bg-red-400 text-white' : '',
										)}
									>
										{error}
									</p>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
