import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { useSaveProject } from '@/hooks/project/useSaveProject'
import { OrganizationListResponse } from '@/model/selfcare/types'
import { setProjectOrganizationId } from '@/state/features/project/projectSlice'
import { useAppDispatch } from '@/state/hooks'
import { TriangleAlertIcon } from 'lucide-react'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export type OrganizationProps = {
	organizations: OrganizationListResponse['organizations']
}

export const OrganizationAlert = ({ organizations }: OrganizationProps) => {
	const { query } = useRouter()
	const dispatch = useAppDispatch()
	const { run: saveProject } = useSaveProject()
	const [value, setValue] = useState<string | undefined>(organizations.find(org => org.slug === query.org)?.id)

	useEffect(() => {
		if (value) {
			dispatch(setProjectOrganizationId(value))
		}
	}, [value, dispatch])

	return (
		<AlertDialog defaultOpen>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>
						<div className={'flex gap-2 items-center'}>
							<TriangleAlertIcon className={'text-orange-600'} /> This project doesn't belong to any organization
						</div>
					</AlertDialogTitle>
					<AlertDialogDescription>
						<p className={'my-2'}>Please select an organization to continue.</p>
						<Select value={value} onValueChange={setValue}>
							<SelectTrigger>
								<SelectValue placeholder={'Select organization ...'} />
							</SelectTrigger>
							<SelectContent>
								{organizations.map(org => (
									<SelectItem value={org.id} key={org.id}>
										{org.name}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogAction
						disabled={!value}
						onClick={async () => {
							await saveProject()
						}}
					>
						Continue
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}

export const OrganizationSelect = ({ organizations }: OrganizationProps) => {
	const { query, push } = useRouter()
	const [value, setValue] = useState<string | undefined>(typeof query.org === 'string' ? query.org : undefined)

	return (
		<Select
			value={value}
			onValueChange={value => {
				setValue(value)
				localStorage.setItem('org_slug', value)
				push(`/${value}/projects`)
			}}
		>
			<SelectTrigger className={'w-auto bg-transparent border-neutral-500 gap-2'}>
				<SelectValue placeholder="Select organization ..." />
			</SelectTrigger>
			<SelectContent>
				{organizations?.map(org => (
					<SelectItem key={org.slug} value={org.slug}>
						{org.name}
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	)
}
